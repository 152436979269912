import { ethers } from "ethers";
import { addresses } from "../constants";
import { abi as ierc20Abi } from "../abi/IERC20.json";
import { abi as uniswapIERC20Abi } from "../abi/UniswapIERC20.json";
import { abi as pairAbi } from "../abi/PairContract.json";
import { abi as liquidityLockerAbi } from "../abi/LiquidityLocker.json";
import { setAll } from "../helpers";

import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBasePairAddressAsyncThunk } from "./interfaces";

export const loadAccountPairDetails = createAsyncThunk(
  "accountPair/loadAccountPairDetails",
  async ({ pair, index = 0, networkID, provider, address }: IBasePairAddressAsyncThunk) => {
    const signer = provider.getSigner();

    const ethBalance = await provider.getBalance(address);

    const pairContract = new ethers.Contract(pair as string, pairAbi, provider);

    const token0 = await pairContract.token0();
    const token1 = await pairContract.token1();

    const token0Contract = new ethers.Contract(token0 as string, uniswapIERC20Abi, signer);
    const token0Symbol = await token0Contract.symbol();
    const token1Contract = new ethers.Contract(token1 as string, uniswapIERC20Abi, signer);
    const token1Symbol = await token1Contract.symbol();

    const pairBalance = await pairContract.balanceOf(address);
    const pairAllowance = await pairContract.allowance(address, addresses[networkID].LIQUIDITY_LOCKER_ADDRESS);

    const liquidityLockerContract = new ethers.Contract(
      addresses[networkID].LIQUIDITY_LOCKER_ADDRESS as string,
      liquidityLockerAbi,
      signer,
    );
    const userNumLockedTokens = await liquidityLockerContract.getUserNumLocksForToken(address, pair);
    let lockEndTime = 0;
    let lockStartTime = 0;
    let lockAmount = "0";
    let lockId = 0;

    if (Number(userNumLockedTokens) !== 0) {
      const userLockForTokenAtIndex = await liquidityLockerContract.getUserLockForTokenAtIndex(address, pair, index);
      lockEndTime = Number(userLockForTokenAtIndex[3]);
      lockStartTime = Number(userLockForTokenAtIndex[0]);
      lockAmount = ethers.utils.formatEther(userLockForTokenAtIndex[1]);
      lockId = Number(userLockForTokenAtIndex[4]);
    }

    return {
      balances: {
        eth: ethers.utils.formatEther(ethBalance),
        pair: ethers.utils.formatEther(pairBalance),
      },
      locking: {
        pairLock: pairAllowance,
        lockStartTime: lockStartTime,
        lockEndTime: lockEndTime,
        lockAmount: lockAmount,
        lockId: lockId,
      },
      pair: `${token0Symbol} / ${token1Symbol}`,
    };
  },
);

interface IAccountSlice {
  balances: {
    eth: string;
    pair: string;
  };
  loading: boolean;
  locking: {
    pairLock: number;
    lockStartTime: number;
    lockEndTime: number;
    lockAmount: string;
    lockId: number;
  };
}

const initialState: IAccountSlice = {
  loading: false,
  balances: { eth: "", pair: "" },
  locking: { pairLock: 0, lockStartTime: 0, lockEndTime: 0, lockAmount: "0", lockId: 0 },
};

const accountPairSlice = createSlice({
  name: "accountPair",
  initialState,
  reducers: {
    fetchAccountPairSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAccountPairDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAccountPairDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAccountPairDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      });
  },
});

export default accountPairSlice.reducer;

export const { fetchAccountPairSuccess } = accountPairSlice.actions;

const baseInfo = (state: RootState) => state.accountPair;

export const getAccountPairState = createSelector(baseInfo, accountPair => accountPair);
