import { useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import Social from "./Social";
import externalUrls from "./externalUrls";
import { chains } from "../../constants";
import { ReactComponent as StakeIcon } from "../../assets/icons/stake.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { trim, shorten } from "../../helpers";
import { useAddress, useWeb3Context } from "src/hooks/web3Context";
import { Paper, Link, Box, Typography, SvgIcon } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import "./sidebar.scss";
import BrandLogo from "../../assets/icons/brand-logo.png";

function NavContent() {
  const [isActive] = useState();
  const address = useAddress();
  const { chainID } = useWeb3Context();

  const checkPage = useCallback((match, location, page) => {
    const currentPath = location.pathname.replace("/", "");
    if (currentPath.indexOf("new-search") >= 0 && page === "new-search") {
      return true;
    }

    if (currentPath.indexOf("edit-search") >= 0 && page === "edit-search") {
      return true;
    }
    return false;
  }, []);

  return (
    <Paper className="dapp-sidebar">
      <Box className="dapp-sidebar-inner" display="flex" justifyContent="space-between" flexDirection="column">
        <div className="dapp-menu-top">
          <Box className="branding-header">
            <Link href="https://safemutual.com/" target="_blank">
              <img src={BrandLogo} style={{ minWdth: "120px", minHeight: "120px", width: "120px" }} alt="" />
              <div className="wallet-link f-32">SafeMutual</div>
            </Link>
            {address && (
              <div className="wallet-link">
                <Link href={`${chains[chainID] && chains[chainID].scanUrl}address/${address}`} target="_blank">
                  {shorten(address)}
                </Link>
              </div>
            )}
          </Box>

          <div className="dapp-menu-links">
            <div className="dapp-nav" id="navbarNav">
              <Link
                component={NavLink}
                id="new-search-nav"
                to="/new-search"
                isActive={(match, location) => {
                  return checkPage(match, location, "new-search");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <Typography variant="h6">
                  <SvgIcon color="primary" component={StakeIcon} />
                  New Lock
                </Typography>
              </Link>

              <Link
                component={NavLink}
                id="edit-search-nav"
                to="/edit-search"
                isActive={(match, location) => {
                  return checkPage(match, location, "edit-search");
                }}
                className={`button-dapp-menu ${isActive ? "active" : ""}`}
              >
                <Typography variant="h6">
                  <SvgIcon color="primary" component={StakeIcon} />
                  Edit/Withdraw
                </Typography>
              </Link>
            </div>
          </div>
        </div>

        {/* <Box className="dapp-menu-bottom" display="flex" justifyContent="space-between" flexDirection="column">
          <div className="dapp-menu-external-links">
            {externalUrls.map(({ url, icon, title, label }, i) => {
              return (
                <Link key={i} href={url} target="_blank" component={url ? "a" : "span"}>
                  <Typography variant="h6">{icon}</Typography>
                  <Typography variant="h6">{title}</Typography>
                  {label ? (
                    <Typography variant="caption" style={{ marginLeft: "8px" }}>
                      {label}
                    </Typography>
                  ) : null}
                </Link>
              );
            })}
          </div>
          <div className="dapp-menu-social">
            <Social />
          </div>
        </Box> */}
      </Box>
    </Paper>
  );
}

export default NavContent;
