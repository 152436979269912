import { ethers, BigNumber } from "ethers";
import { addresses } from "../constants";
import { abi as liquidityLockerAbi } from "../abi/LiquidityLocker.json";
import { abi as uniswapIERC20Abi } from "../abi/UniswapIERC20.json";
import { abi as pairAbi } from "../abi/PairContract.json";
import { clearPendingTxn, fetchPendingTxns, getStakingTypeText } from "./PendingTxnsSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAccountPairSuccess, loadAccountPairDetails } from "./AccountPairSlice";
import { error, info } from "../slices/MessagesSlice";
import {
  IActionValueAsyncThunk,
  IChangeApprovalAsyncThunk,
  IJsonRPCError,
  ISearchValueAsyncThunk,
  IValueAsyncThunk,
  IEditUnlockDateAsyncThunk,
} from "./interfaces";
import { chains } from "../constants"

interface IUAData {
  address: string;
  value: string;
  approved: boolean;
  txHash: string | null;
  type: string | null;
}

function alreadyApprovedToken(stakeAllowance: BigNumber) {
  // set defaults
  let bigZero = BigNumber.from("0");
  const applicableAllowance = stakeAllowance;

  // check if allowance exists
  if (applicableAllowance.gt(bigZero)) return true;

  return false;
}

export const changeApproval = createAsyncThunk(
  "stake/changeApproval",
  async ({ pair, provider, address, networkID }: IChangeApprovalAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    const pairContract = new ethers.Contract(pair, pairAbi, signer);
    let approveTx;
    let lockAllowance = await pairContract.allowance(address, addresses[networkID].LIQUIDITY_LOCKER_ADDRESS);

    // return early if approval has already happened
    if (alreadyApprovedToken(lockAllowance)) {
      dispatch(info("Approval completed."));
      return dispatch(
        fetchAccountPairSuccess({
          locking: {
            pairLock: +lockAllowance,
          },
        }),
      );
    }

    try {
      approveTx = await pairContract.approve(
        addresses[networkID].LIQUIDITY_LOCKER_ADDRESS,
        ethers.utils.parseUnits("10000000000000000000", "ether").toString(),
      );

      const text = "Approve " + "Locking";
      const pendingTxnType = "approve_locking";
      dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));

      await approveTx.wait();
    } catch (e: unknown) {
      dispatch(error((e as IJsonRPCError).message));
      return;
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }

    // go get fresh allowances
    lockAllowance = await pairContract.allowance(address, addresses[networkID].LIQUIDITY_LOCKER_ADDRESS);

    return dispatch(
      fetchAccountPairSuccess({
        locking: {
          pairLock: +lockAllowance,
        },
      }),
    );
  },
);

export const changeStake = createAsyncThunk(
  "stake/changeStake",
  async (
    {
      action,
      value,
      pair,
      unlockDate,
      withdrawer,
      referrer,
      provider,
      address,
      networkID,
      callback,
    }: IActionValueAsyncThunk,
    { dispatch },
  ) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    let liquidityLockerContract;
    liquidityLockerContract = new ethers.Contract(
      addresses[networkID].LIQUIDITY_LOCKER_ADDRESS as string,
      liquidityLockerAbi,
      signer,
    );

    let stakeTx;
    let uaData: IUAData = {
      address: address,
      value: value,
      approved: true,
      txHash: null,
      type: null,
    };

    try {
      uaData.type = "lock";
      stakeTx = await liquidityLockerContract.lockLPToken(
        pair,
        ethers.utils.parseUnits(value, "ether"),
        Number(unlockDate),
        referrer,
        true,
        withdrawer,
        { value: ethers.utils.parseUnits(chains[networkID].ethFee, "ether") },
      );
      const pendingTxnType = "locking";
      uaData.txHash = stakeTx.hash;
      dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: getStakingTypeText(), type: pendingTxnType }));
      callback?.();
      await stakeTx.wait();
      await new Promise<void>((resolve, reject) => {
        setTimeout(async () => {
          try {
            await dispatch(loadAccountPairDetails({ networkID, address, provider }));
            resolve();
          } catch (error) {
            reject(error);
          }
        }, 5000);
      });
    } catch (e: unknown) {
      uaData.approved = false;
      const rpcError = e as IJsonRPCError;
      if (rpcError.code === -32603 && rpcError.message.indexOf("ds-math-sub-underflow") >= 0) {
        dispatch(
          error("You may be trying to lock more than your balance! Error code: 32603. Message: ds-math-sub-underflow"),
        );
      } else {
        console.log(rpcError);
        dispatch(error(rpcError.message));
      }
      return;
    } finally {
      if (stakeTx) {
        dispatch(clearPendingTxn(stakeTx.hash));
      }
    }
  },
);

export const relock = createAsyncThunk(
  "stake/relock",
  async (
    { index, pair, lockId, unlockDate, provider, address, networkID, callback }: IEditUnlockDateAsyncThunk,
    { dispatch },
  ) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    let liquidityLockerContract;
    liquidityLockerContract = new ethers.Contract(
      addresses[networkID].LIQUIDITY_LOCKER_ADDRESS as string,
      liquidityLockerAbi,
      signer,
    );

    let stakeTx;
    let uaData: IUAData = {
      address: address,
      value: "",
      approved: true,
      txHash: null,
      type: null,
    };

    try {
      uaData.type = "relock";
      stakeTx = await liquidityLockerContract.relock(pair, Number(index), Number(lockId), Number(unlockDate));
      const pendingTxnType = "relocking";
      uaData.txHash = stakeTx.hash;
      dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: getStakingTypeText(), type: pendingTxnType }));
      callback?.();
      await stakeTx.wait();
      await new Promise<void>((resolve, reject) => {
        setTimeout(async () => {
          try {
            await dispatch(loadAccountPairDetails({ networkID, address, provider }));
            resolve();
          } catch (error) {
            reject(error);
          }
        }, 5000);
      });
    } catch (e: unknown) {
      uaData.approved = false;
      const rpcError = e as IJsonRPCError;
      dispatch(error(rpcError.message));
      return;
    } finally {
      if (stakeTx) {
        dispatch(clearPendingTxn(stakeTx.hash));
      }
    }
  },
);

export const transferOwnership = createAsyncThunk(
  "stake/transferOwnership",
  async (
    { index, pair, lockId, unlockDate, provider, address, networkID, callback }: IEditUnlockDateAsyncThunk,
    { dispatch },
  ) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    let liquidityLockerContract;
    liquidityLockerContract = new ethers.Contract(
      addresses[networkID].LIQUIDITY_LOCKER_ADDRESS as string,
      liquidityLockerAbi,
      signer,
    );

    let stakeTx;
    let uaData: IUAData = {
      address: address,
      value: "",
      approved: true,
      txHash: null,
      type: null,
    };

    try {
      uaData.type = "transfer";
      stakeTx = await liquidityLockerContract.transferLockOwnership(pair, Number(index), Number(lockId), unlockDate);
      const pendingTxnType = "transfering";
      uaData.txHash = stakeTx.hash;
      dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: getStakingTypeText(), type: pendingTxnType }));
      callback?.();
      await stakeTx.wait();
      await new Promise<void>((resolve, reject) => {
        setTimeout(async () => {
          try {
            await dispatch(loadAccountPairDetails({ networkID, address, provider }));
            resolve();
          } catch (error) {
            reject(error);
          }
        }, 5000);
      });
    } catch (e: unknown) {
      uaData.approved = false;
      const rpcError = e as IJsonRPCError;
      dispatch(error(rpcError.message));
      return;
    } finally {
      if (stakeTx) {
        dispatch(clearPendingTxn(stakeTx.hash));
      }
    }
  },
);

export const incrementLock = createAsyncThunk(
  "stake/incrementLock",
  async (
    { index, pair, lockId, unlockDate, provider, address, networkID, callback }: IEditUnlockDateAsyncThunk,
    { dispatch },
  ) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    let liquidityLockerContract;
    liquidityLockerContract = new ethers.Contract(
      addresses[networkID].LIQUIDITY_LOCKER_ADDRESS as string,
      liquidityLockerAbi,
      signer,
    );

    let stakeTx;
    let uaData: IUAData = {
      address: address,
      value: unlockDate,
      approved: true,
      txHash: null,
      type: null,
    };

    try {
      uaData.type = "increment";
      stakeTx = await liquidityLockerContract.incrementLock(
        pair,
        Number(index),
        Number(lockId),
        ethers.utils.parseUnits(unlockDate, "ether"),
      );
      const pendingTxnType = "incrementing";
      uaData.txHash = stakeTx.hash;
      dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: getStakingTypeText(), type: pendingTxnType }));
      callback?.();
      await stakeTx.wait();
      await new Promise<void>((resolve, reject) => {
        setTimeout(async () => {
          try {
            await dispatch(loadAccountPairDetails({ networkID, address, provider }));
            resolve();
          } catch (error) {
            reject(error);
          }
        }, 5000);
      });
    } catch (e: unknown) {
      uaData.approved = false;
      const rpcError = e as IJsonRPCError;
      if (rpcError.code === -32603 && rpcError.message.indexOf("ds-math-sub-underflow") >= 0) {
        dispatch(
          error("You may be trying to lock more than your balance! Error code: 32603. Message: ds-math-sub-underflow"),
        );
      } else {
        console.log(rpcError);
        dispatch(error(rpcError.message));
      }
      return;
    } finally {
      if (stakeTx) {
        dispatch(clearPendingTxn(stakeTx.hash));
      }
    }
  },
);

export const splitLock = createAsyncThunk(
  "stake/splitLock",
  async (
    { index, pair, lockId, unlockDate, provider, address, networkID, callback }: IEditUnlockDateAsyncThunk,
    { dispatch },
  ) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    let liquidityLockerContract;
    liquidityLockerContract = new ethers.Contract(
      addresses[networkID].LIQUIDITY_LOCKER_ADDRESS as string,
      liquidityLockerAbi,
      signer,
    );

    let stakeTx;
    let uaData: IUAData = {
      address: address,
      value: unlockDate,
      approved: true,
      txHash: null,
      type: null,
    };

    try {
      uaData.type = "split";
      stakeTx = await liquidityLockerContract.splitLock(
        pair,
        Number(index),
        Number(lockId),
        ethers.utils.parseUnits(unlockDate, "ether"),
        { value: ethers.utils.parseUnits(chains[networkID].ethFee, "ether") },
      );
      const pendingTxnType = "spliting";
      uaData.txHash = stakeTx.hash;
      dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: getStakingTypeText(), type: pendingTxnType }));
      callback?.();
      await stakeTx.wait();
      await new Promise<void>((resolve, reject) => {
        setTimeout(async () => {
          try {
            await dispatch(loadAccountPairDetails({ networkID, address, provider }));
            resolve();
          } catch (error) {
            reject(error);
          }
        }, 5000);
      });
    } catch (e: unknown) {
      uaData.approved = false;
      const rpcError = e as IJsonRPCError;
      if (rpcError.code === -32603 && rpcError.message.indexOf("ds-math-sub-underflow") >= 0) {
        dispatch(
          error("You may be trying to lock more than your balance! Error code: 32603. Message: ds-math-sub-underflow"),
        );
      } else {
        console.log(rpcError);
        dispatch(error(rpcError.message));
      }
      return;
    } finally {
      if (stakeTx) {
        dispatch(clearPendingTxn(stakeTx.hash));
      }
    }
  },
);

export const withdraw = createAsyncThunk(
  "stake/withdraw",
  async (
    { index, pair, lockId, unlockDate, provider, address, networkID, callback }: IEditUnlockDateAsyncThunk,
    { dispatch },
  ) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    let liquidityLockerContract;
    liquidityLockerContract = new ethers.Contract(
      addresses[networkID].LIQUIDITY_LOCKER_ADDRESS as string,
      liquidityLockerAbi,
      signer,
    );

    let stakeTx;
    let uaData: IUAData = {
      address: address,
      value: unlockDate,
      approved: true,
      txHash: null,
      type: null,
    };

    try {
      uaData.type = "withdraw";
      stakeTx = await liquidityLockerContract.withdraw(
        pair,
        Number(index),
        Number(lockId),
        ethers.utils.parseUnits(unlockDate, "ether")
      );
      const pendingTxnType = "withdrawing";
      uaData.txHash = stakeTx.hash;
      dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: getStakingTypeText(), type: pendingTxnType }));
      callback?.();
      await stakeTx.wait();
      await new Promise<void>((resolve, reject) => {
        setTimeout(async () => {
          try {
            await dispatch(loadAccountPairDetails({ networkID, address, provider }));
            resolve();
          } catch (error) {
            reject(error);
          }
        }, 5000);
      });
    } catch (e: unknown) {
      uaData.approved = false;
      const rpcError = e as IJsonRPCError;
      if (rpcError.code === -32603 && rpcError.message.indexOf("ds-math-sub-underflow") >= 0) {
        dispatch(
          error("You may be trying to lock more than your balance! Error code: 32603. Message: ds-math-sub-underflow"),
        );
      } else {
        console.log(rpcError);
        dispatch(error(rpcError.message));
      }
      return;
    } finally {
      if (stakeTx) {
        dispatch(clearPendingTxn(stakeTx.hash));
      }
    }
  },
);

export const changeSearch = createAsyncThunk(
  "stake/changeSearch",
  async ({ value, provider, address, networkID, callback }: ISearchValueAsyncThunk, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    const pair = new ethers.Contract(value, pairAbi, signer);
    const token0 = await pair.token0();
    const token1 = await pair.token1();

    const token0Contract = new ethers.Contract(token0 as string, uniswapIERC20Abi, signer);
    const token0Symbol = await token0Contract.symbol();
    const token1Contract = new ethers.Contract(token1 as string, uniswapIERC20Abi, signer);
    const token1Symbol = await token1Contract.symbol();

    const liquidityLockerContract = new ethers.Contract(
      addresses[networkID].LIQUIDITY_LOCKER_ADDRESS as string,
      liquidityLockerAbi,
      signer,
    );
    const userLockedForToken = await liquidityLockerContract.getUserNumLocksForToken(address, value);

    callback?.(token0Symbol, token1Symbol, Number(userLockedForToken));
  },
);
