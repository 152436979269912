import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, FormControl, Grid, InputLabel, OutlinedInput, Paper, Typography, Zoom } from "@material-ui/core";
import { Link } from "react-router-dom";
import { trim } from "../../helpers";
import PairLogo from "../../components/PairLogo";
import { changeSearch } from "../../slices/StakeThunk";
import "./edit-search.scss";
import { useWeb3Context } from "src/hooks/web3Context";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { Skeleton } from "@material-ui/lab";
import { error } from "../../slices/MessagesSlice";
import { ethers } from "ethers";
import { chains } from "src/constants";

function Search() {
  const dispatch = useDispatch();
  const { provider, address, connected, connect, chainID } = useWeb3Context();

  const [pair, setPair] = useState("");
  const [token0, setToken0] = useState("");
  const [token1, setToken1] = useState("");
  const [userLockedForToken, setLockedInfo] = useState(0);
  const [zoomed, setZoomed] = useState(false);

  const onChangeStake = async value => {
    await dispatch(
      changeSearch({
        address,
        value: value.toString(),
        provider,
        networkID: chainID,
        callback: (token0, token1, userLockedForToken) => {
          setToken0(token0);
          setToken1(token1);
          setLockedInfo(userLockedForToken);
          setPair(value.toString());
        },
      }),
    );
  };

  let modalButton = [];

  modalButton.push(
    <Button variant="contained" color="primary" className="connect-button" onClick={connect} key={1}>
      Connect Wallet
    </Button>,
  );

  let resultSection = [];

  for (let index = 0; index < userLockedForToken; index++) {
    resultSection.push(<div className="data-row-pairbox">
    <div style={{ display: "flex" }}>
      <PairLogo />
      <Typography variant="h5">{`${token0} / ${token1}`}</Typography>
    </div>
    <Button
      className="stake-button"
      variant="contained"
      color="primary"
      style={{ marginTop: "10px" }}
    >
      <Link to={`edit-lock/${pair}/${index}/main`} className="stake-button-link">
        CONTIUE
      </Link>
    </Button>
  </div>,)
    
  }

  return (
    <div id="stake-view">
      <Zoom in={true} onEntered={() => setZoomed(true)}>
        <Paper className={`unicrypt-card`}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <div className="card-header">
                <Typography variant="h5">Edit / Withdraw</Typography>
              </div>

              <div className="staking-area">
                {!address ? (
                  <div className="stake-wallet-notification">
                    <div className="wallet-menu" id="wallet-menu">
                      {modalButton}
                    </div>
                    <Typography variant="h6">Connect your wallet to lock</Typography>
                  </div>
                ) : (
                  <>
                    <div className={`stake-user-data`}>
                      <Typography variant="h6">{`Enter the ${chains[chainID] && chains[chainID].dexLabel} pair address youd like to access`}</Typography>
                    </div>
                    <Box className="stake-action-area">
                      <Box className="stake-action-row" display="flex" alignItems="center">
                        <FormControl className="unicrypt-input" variant="outlined" color="primary">
                          <OutlinedInput
                            type="text"
                            placeholder={`${chains[chainID] && chains[chainID].dexLabel} pair address...`}
                            className="stake-input"
                            onChange={e => onChangeStake(e.target.value)}
                          />
                        </FormControl>
                      </Box>
                      <div className={`stake-address-eg`}>
                        <Typography variant="body1" className={`stake-address-eg-typography`}>
                          e.g. 0xc736cA3d9b1E90Af4230BD8F9626528B3D4e0Ee0
                        </Typography>
                      </div>
                      {token0 !== "" && token1 !== "" ? resultSection : (
                        <></>
                      )}
                    </Box>
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Zoom>
    </div>
  );
}

export default Search;
