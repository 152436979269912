import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  OutlinedInput,
  Paper,
  Typography,
  Zoom,
  InputAdornment,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker } from "@material-ui/pickers";
import { useParams } from "react-router-dom";
import { trim } from "../../helpers";
import { changeApproval, changeStake } from "../../slices/StakeThunk";
import { loadAccountPairDetails } from "../../slices/AccountPairSlice";
import PairLogo from "../../components/PairLogo";
import "./new-lock.scss";
import { useWeb3Context } from "src/hooks/web3Context";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { Skeleton } from "@material-ui/lab";
import { error } from "../../slices/MessagesSlice";
import { ethers } from "ethers";
import { chains } from "src/constants";

function NewLock() {
  const { pair } = useParams();
  const dispatch = useDispatch();
  const { provider, address, connected, connect, chainID, walletChecked } = useWeb3Context();
  const [zoomed, setZoomed] = useState(false);

  const loadAccountPair = useCallback(() => {
    dispatch(loadAccountPairDetails({ pair, networkID: chainID, address, provider: provider }));
  }, [connected]);

  useEffect(() => {
    if (connected) {
      loadAccountPair();
      setUnlockerAddress(address);
    }
  }, [connected]);

  useEffect(() => {
    if (walletChecked) {
      const updateAccountDetailInterval = setInterval(() => {
        dispatch(loadAccountPairDetails({ pair, networkID: chainID, address, provider: provider }));
      }, 1000 * 60 * 10);
      return () => {
        clearInterval(updateAccountDetailInterval);
      };
    }
  }, [walletChecked]);

  const pairName = useSelector(state => {
    return state.accountPair.pair;
  });

  const pairBalance = useSelector(state => {
    return state.accountPair.balances && state.accountPair.balances.pair;
  });

  const pairAllowance = useSelector(state => {
    return state.accountPair.locking && state.accountPair.locking.pairLock;
  });

  const hasAllowance = useCallback(() => {
    return pairAllowance > 0;
  }, [pairAllowance]);

  const isAllowanceDataLoading = pairAllowance == null;

  const [quantity, setQuantity] = useState("");

  const setMax = () => {
    setQuantity(pairBalance);
  };

  const [selectedDate, handleDateChange] = useState(new Date());

  const [unlockerAddress, setUnlockerAddress] = useState(address);
  const [lockerNeeded, setLockerNeeded] = useState(false);

  const [referralAddress, setReferralAddress] = useState("0x0000000000000000000000000000000000000000");
  const [referralNeeded, setReferralNeeded] = useState(false);

  const isAppLoading = useSelector(state => state.app.loading);

  const ethBalance = useSelector(state => {
    return state.account.balances && state.account.balances.eth;
  });

  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });

  const onSeekApproval = async () => {
    await dispatch(changeApproval({ address, pair, provider, networkID: chainID }));
  };

  const onChangeStake = async () => {
    // eslint-disable-next-line no-restricted-globals
    let value;
    value = quantity;
    if (isNaN(value) || value == 0 || value === "") {
      // eslint-disable-next-line no-alert
      return dispatch(error("Please enter a value!"));
    }
    let unlockDate;
    unlockDate = selectedDate.getTime() / 1000;

    // 1st catch if quantity > balance
    let gweiValue = ethers.utils.parseUnits(value, "ether");
    if (gweiValue.gt(ethers.utils.parseUnits(pairBalance, "ether"))) {
      return dispatch(error("You cannot lock more than your pair balance."));
    }

    await dispatch(
      changeStake({
        address,
        action: "lock",
        value: value.toString(),
        pair: pair,
        unlockDate: unlockDate.toFixed(0),
        withdrawer: unlockerAddress,
        referrer: referralAddress,
        provider,
        networkID: chainID,
        callback: () => setQuantity(""),
      }),
    );
  };

  let modalButton = [];
  modalButton.push(
    <Button variant="contained" color="primary" className="connect-button" onClick={connect} key={1}>
      Connect Wallet
    </Button>,
  );

  return (
    <div id="stake-view">
      <Zoom in={true} onEntered={() => setZoomed(true)}>
        <Paper className={`unicrypt-card`}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <div className="card-header">
                <Typography variant="h5" className="stake-wallet-notification">
                  Lock Liquidity
                </Typography>
              </div>
              <div className="staking-area">
                {!address ? (
                  <div className="stake-wallet-notification">
                    <div className="wallet-menu" id="wallet-menu">
                      {modalButton}
                    </div>
                    <Typography variant="h6">Connect your wallet to lock</Typography>
                  </div>
                ) : (
                  <>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <PairLogo />
                      <Typography variant="h5">{pairName}</Typography>
                    </div>
                    <div className={`stake-user-data`}>
                      <Typography variant="h6" className="stake-wallet-notification">
                        Lock how many LP tokens?
                      </Typography>
                    </div>
                    <Box className="stake-action-area">
                      <Typography variant="body2" style={{ textAlign: "end" }}>{`Balance: ${trim(
                        pairBalance,
                        4,
                      )}`}</Typography>
                      <Box className="stake-action-row" display="flex" alignItems="center">
                        <FormControl className="unicrypt-input" variant="outlined" color="primary">
                          <InputLabel htmlFor="amount-input"></InputLabel>
                          <OutlinedInput
                            id="amount-input"
                            type="number"
                            placeholder="Enter an amount"
                            className="stake-input"
                            value={quantity}
                            onChange={e => setQuantity(e.target.value)}
                            labelWidth={0}
                            endAdornment={
                              <InputAdornment position="end">
                                <Button variant="text" onClick={setMax} color="inherit">
                                  Max
                                </Button>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </Box>
                    </Box>
                    <div className={`stake-user-data`}>
                      <Typography variant="h6" className="stake-wallet-notification">
                        Unlock Date
                      </Typography>
                      <DateTimePicker
                        variant="inline"
                        inputVariant="outlined"
                        value={selectedDate}
                        onChange={handleDateChange}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className={`stake-user-data`}>
                      <Typography variant="h6" className="stake-wallet-notification">
                        Who can withdraw the tokens?
                      </Typography>
                      <div className={`stake-button-group`}>
                        <Button
                          className={`stake-group-button`}
                          variant={`${!lockerNeeded ? "contained" : "text"}`}
                          color="primary"
                          onClick={() => {
                            setLockerNeeded(false);
                            setUnlockerAddress(address);
                          }}
                        >
                          Me
                        </Button>
                        <Button
                          className={`stake-group-button`}
                          variant={`${lockerNeeded ? "contained" : "text"}`}
                          color="primary"
                          onClick={() => setLockerNeeded(true)}
                        >
                          Someone else
                        </Button>
                      </div>
                    </div>
                    {lockerNeeded && (
                      <Box className="stake-action-row" display="flex" alignItems="center">
                        <FormControl className="unicrypt-input" variant="outlined" color="primary">
                          <OutlinedInput
                            type="text"
                            placeholder="Unlocker address"
                            className="stake-input"
                            onChange={e => setUnlockerAddress(e.target.value)}
                          />
                        </FormControl>
                      </Box>
                    )}
                    <div className={`stake-user-data`}>
                      <Typography variant="h6" className="stake-wallet-notification">
                        Do you have a valid referral address?
                      </Typography>
                      <Typography variant="body2" className="stake-wallet-notification">
                        Enjoy a 10% flatrate discount if so!
                      </Typography>
                      <div className={`stake-button-group`}>
                        <Button
                          className={`stake-group-button`}
                          variant={`${!referralNeeded ? "contained" : "text"}`}
                          color="primary"
                          onClick={() => {
                            setReferralNeeded(false);
                            setReferralAddress("0x0000000000000000000000000000000000000000");
                          }}
                        >
                          No
                        </Button>
                        <Button
                          className={`stake-group-button`}
                          variant={`${referralNeeded ? "contained" : "text"}`}
                          color="primary"
                          onClick={() => setReferralNeeded(true)}
                        >
                          Yes
                        </Button>
                      </div>
                    </div>
                    {referralNeeded && (
                      <Box className="stake-action-row" display="flex" alignItems="center">
                        <FormControl className="unicrypt-input" variant="outlined" color="primary">
                          <OutlinedInput
                            type="text"
                            placeholder="Unlocker address"
                            className="stake-input"
                            onChange={e => setReferralAddress(e.target.value)}
                          />
                        </FormControl>
                      </Box>
                    )}
                    <div className={`stake-user-data`}>
                      <Typography variant="h6" className="stake-wallet-notification">
                        Fee options
                      </Typography>
                      <div className={`stake-button-group`}>
                        <Button className={`stake-group-button`} variant="contained" color="primary" disabled>
                          {`${chains[chainID] && chains[chainID].ethFee} ${chains[chainID] && chains[chainID].eth} (+ 1% ${chains[chainID] && chains[chainID].dexLabel})`}
                        </Button>
                      </div>
                      <Typography variant="body2" className="stake-wallet-notification">
                        {`Your Balance: ${!isAppLoading && trim(ethBalance, 3)} ${chains[chainID] && chains[chainID].eth}`}
                      </Typography>
                    </div>
                    <div className={`stake-user-data`} style={{ marginTop: "24px", marginBottom: "24px" }}>
                      <Typography variant="h6" className="stake-wallet-notification">
                        Once tokens are locked they cannot be withdrawn under any circumstances until the timer has
                        expired. Please ensure the parameters are correct, as they are final.
                      </Typography>
                    </div>
                    {address && !isAllowanceDataLoading && hasAllowance() ? (
                      <Button
                        className="stake-button"
                        variant="contained"
                        color="primary"
                        disabled={isPendingTxn(pendingTransactions, "locking")}
                        onClick={() => {
                          onChangeStake();
                        }}
                      >
                        {txnButtonText(pendingTransactions, "locking", "Lock")}
                      </Button>
                    ) : (
                      <Button
                        className="stake-button"
                        variant="contained"
                        color="primary"
                        disabled={isPendingTxn(pendingTransactions, "approve_locking")}
                        onClick={() => {
                          onSeekApproval();
                        }}
                      >
                        {txnButtonText(pendingTransactions, "approve_locking", "Approve")}
                      </Button>
                    )}
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Zoom>
    </div>
  );
}

export default NewLock;
