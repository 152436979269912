import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addresses, chains } from "../../constants";
import { Link, SvgIcon, Popper, Button, Paper, Typography, Divider, Box, Fade, Slide } from "@material-ui/core";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-fill.svg";
import { trim } from "../../helpers";

import "./unicrypt.scss";
import { useWeb3Context } from "../../hooks/web3Context";

function UnicryptMenu({ changeChainId, chainId }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const isEthereumAPIAvailable = window.ethereum;
  const { chainID, eth } = useWeb3Context();

  const ethBalance = useSelector(state => {
    return state.account.balances && state.account.balances.eth;
  });

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = "unicrypt-popper";

  return (
    <>
      <Box component="div">
        <Button size="large" variant="contained" color="secondary" title="Unicrypt">
          {!ethBalance ? (
            <>
              <SvgIcon component={InfoIcon} color="primary" />
              Wrong Network
            </>
          ) : (
            <>
              {trim(ethBalance, 3)}
              {chains[chainID] ? chains[chainID].eth : eth}
            </>
          )}
        </Button>
      </Box>
      <Box id="unicrypt-menu-button" component="div" onClick={e => handleClick(e)} aria-describedby={id}>
        <Button
          size="large"
          variant="contained"
          color="secondary"
          title="chain"
          aria-describedby={id}
          className="network-button"
        >
          {chainId === 56 && "BSC"}
          {chainId === 1 && "ETH"}
          {chainId === 43114 && "AVAX"}
          {chainId === 250 && "FTM"}
          {chainId === 137 && "POLYGON"}
          {chainId === 4 && "Rinkby ETH"}
          {chainId === 2000 && "DOGE"}
        </Button>
        <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
          <Paper className="unicrypt-menu">
            <Box component="div" style={{width: "132px"}}>
              <Link href={`https://bsc.safemutual.com`} target="_blank" rel="noreferrer">
                <Button size="large" variant="contained" color="secondary" fullWidth>
                  BSC
                </Button>
              </Link>
              <Link href={`https://eth.safemutual.com`} target="_blank" rel="noreferrer">
                <Button size="large" variant="contained" color="secondary" fullWidth>
                  ETH
                </Button>
              </Link>
              <Link href={`https://avax.safemutual.com`} target="_blank" rel="noreferrer">
                <Button size="large" variant="contained" color="secondary" fullWidth>
                  AVAX
                </Button>
              </Link>
              <Link href={`https://fantom.safemutual.com`} target="_blank" rel="noreferrer">
                <Button size="large" variant="contained" color="secondary" fullWidth>
                  FTM
                </Button>
              </Link>
              <Link href={`https://polygon.safemutual.com`} target="_blank" rel="noreferrer">
                <Button size="large" variant="contained" color="secondary" fullWidth>
                  POLYGON
                </Button>
              </Link>
              <Link href={`https://doge.safemutual.com`} target="_blank" rel="noreferrer">
                <Button size="large" variant="contained" color="secondary" fullWidth>
                  DOGE
                </Button>
              </Link>
              <Divider />
              {/* <Link href={`https://rinkbyeth.safemutual.com`} target="_blank" rel="noreferrer">
                <Button size="large" variant="contained" color="secondary" fullWidth>
                  Rinkby ETH
                </Button>
              </Link> */}
            </Box>
          </Paper>
        </Popper>
      </Box>
    </>
  );
}

export default UnicryptMenu;
