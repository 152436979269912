import { useState, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link as LinkExternal,
  OutlinedInput,
  Paper,
  Typography,
  Zoom,
  InputAdornment,
  Popper,
  MenuItem,
  MenuList,
  Grow,
  ClickAwayListener,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker } from "@material-ui/pickers";
import { useParams } from "react-router-dom";
import { chains } from "src/constants";
import { trim, shorten } from "../../helpers";
import { relock, transferOwnership, incrementLock, splitLock, withdraw } from "../../slices/StakeThunk";
import { loadAccountPairDetails } from "../../slices/AccountPairSlice";
import PairLogo from "../../components/PairLogo";
import "./edit-lock.scss";
import { useWeb3Context } from "src/hooks/web3Context";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { Skeleton } from "@material-ui/lab";
import { error } from "../../slices/MessagesSlice";
import { ethers } from "ethers";
import { recoverPublicKey } from "ethers/lib/utils";

function EditLock() {
  const { pair, index, tab } = useParams();
  const dispatch = useDispatch();
  const { provider, address, connected, connect, chainID } = useWeb3Context();
  const [zoomed, setZoomed] = useState(false);

  const loadAccountPair = useCallback(() => {
    dispatch(loadAccountPairDetails({ pair, index, networkID: chainID, address, provider: provider }));
  }, [connected]);

  useEffect(() => {
    if (connected) {
      loadAccountPair();
      handleDateChange(new Date(lockEndTime * 1000));
    }
  }, [connected]);

  const [quantity, setQuantity] = useState("");

  const [lockedQuantity, setLockedQuantity] = useState("");

  const setMax = () => {
    setQuantity(pairBalance);
  };

  const setLockedMax = () => {
    setLockedQuantity(lockAmount);
  };

  const accountPairLoading = useSelector(state => {
    return state.accountPair.loading;
  });

  const pairName = useSelector(state => {
    return state.accountPair.pair;
  });

  const pairBalance = useSelector(state => {
    return state.accountPair.balances && state.accountPair.balances.pair;
  });

  const lockEndTime = useSelector(state => {
    return state.accountPair.locking && state.accountPair.locking.lockEndTime;
  });

  const lockStartTime = useSelector(state => {
    return state.accountPair.locking && state.accountPair.locking.lockStartTime;
  });

  const lockAmount = useSelector(state => {
    return state.accountPair.locking && state.accountPair.locking.lockAmount;
  });

  const lockId = useSelector(state => {
    return state.accountPair.locking && state.accountPair.locking.lockId;
  });

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [selectedDate, handleDateChange] = useState(new Date());

  const onRelock = async () => {
    let unlockDate;
    unlockDate = selectedDate.getTime() / 1000;

    if (unlockDate < lockEndTime) {
      // eslint-disable-next-line no-alert
      return dispatch(error("Lock date needs to be after current lock date."));
    }

    await dispatch(
      relock({
        address,
        index: index,
        lockId,
        pair: pair,
        unlockDate: unlockDate.toFixed(0),
        provider,
        networkID: chainID,
        callback: () => {},
      }),
    );
  };

  const [ownerAddress, setOwnerAddress] = useState("");

  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });

  const onTransferOwnership = async () => {
    if (ownerAddress === "") {
      // eslint-disable-next-line no-alert
      return dispatch(error("Please enter an address!"));
    }

    await dispatch(
      transferOwnership({
        address,
        index: index,
        lockId,
        pair: pair,
        unlockDate: ownerAddress,
        provider,
        networkID: chainID,
        callback: () => {},
      }),
    );
  };

  const onIncrementLock = async () => {
    let value;
    value = quantity;
    if (isNaN(value) || value == 0 || value === "") {
      // eslint-disable-next-line no-alert
      return dispatch(error("Please enter a value!"));
    }

    // 1st catch if quantity > balance
    let gweiValue = ethers.utils.parseUnits(value, "ether");
    if (gweiValue.gt(ethers.utils.parseUnits(pairBalance, "ether"))) {
      return dispatch(error("You cannot lock more than your pair balance."));
    }

    await dispatch(
      incrementLock({
        address,
        index: index,
        lockId,
        pair: pair,
        unlockDate: value.toString(),
        provider,
        networkID: chainID,
        callback: () => {
          setQuantity("");
        },
      }),
    );
  };

  const onSplitLock = async () => {
    let value;
    value = quantity;
    if (isNaN(value) || value == 0 || value === "0") {
      // eslint-disable-next-line no-alert
      return dispatch(error("Please enter a value!"));
    }

    // 1st catch if quantity > balance
    let gweiValue = ethers.utils.parseUnits(value, "ether");
    if (gweiValue.gt(ethers.utils.parseUnits(pairBalance, "ether"))) {
      return dispatch(error("You cannot lock more than your pair balance."));
    }

    await dispatch(
      splitLock({
        address,
        index: index,
        lockId,
        pair: pair,
        unlockDate: value.toString(),
        provider,
        networkID: chainID,
        callback: () => {
          setQuantity("");
        },
      }),
    );
  };

  const onWithdraw = async () => {
    let value;
    value = lockedQuantity;
    if (isNaN(value) || value == 0 || value === "0") {
      // eslint-disable-next-line no-alert
      return dispatch(error("Please enter a value!"));
    }

    // 1st catch if quantity > balance
    let gweiValue = ethers.utils.parseUnits(value, "ether");
    if (gweiValue.gt(ethers.utils.parseUnits(lockAmount, "ether"))) {
      return dispatch(error("You cannot lock more than your pair balance."));
    }

    await dispatch(
      withdraw({
        address,
        index: index,
        lockId,
        pair: pair,
        unlockDate: value.toString(),
        provider,
        networkID: chainID,
        callback: () => {
          setLockedQuantity("");
        },
      }),
    );
  };

  let modalButton = [];
  modalButton.push(
    <Button variant="contained" color="primary" className="connect-button" onClick={connect} key={1}>
      Connect Wallet
    </Button>,
  );

  return (
    <div id="stake-view">
      <Zoom in={true} onEntered={() => setZoomed(true)}>
        <Paper className={`unicrypt-card`}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <div className="card-header">
                <Typography variant="h5" className="stake-wallet-notification">
                  Edit/Withdraw liquidity
                </Typography>
              </div>
              <div className="staking-area">
                {!address ? (
                  <div className="stake-wallet-notification">
                    <div className="wallet-menu" id="wallet-menu">
                      {modalButton}
                    </div>
                    <Typography variant="h6">Connect your wallet to lock</Typography>
                  </div>
                ) : (
                  !accountPairLoading && (
                    <>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <PairLogo />
                        <Typography variant="h5">{pairName}</Typography>
                      </div>
                      <div className={`stake-user-data`}>
                        <Typography variant="body2" className="stake-wallet-notification">
                          <LinkExternal href={`${chains[chainID].scanUrl}address/${pair}`} target="_blank">
                            {shorten(pair)}
                          </LinkExternal>
                        </Typography>
                      </div>
                      <div className="data-row-pairbox">
                        {tab === "main" && (
                          <>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <div>
                                <Typography variant="h5">{`99.7% Locked`}</Typography>
                                <Typography variant="body2">{`${trim(lockAmount, 5)} UNI-V2`}</Typography>
                              </div>
                              <div>
                                <Button
                                  ref={anchorRef}
                                  id="composition-button"
                                  aria-controls={open ? "composition-menu" : undefined}
                                  aria-expanded={open ? "true" : undefined}
                                  aria-haspopup="true"
                                  onClick={handleToggle}
                                  variant="outlined"
                                  size="small"
                                >
                                  ⚙
                                </Button>
                                <Popper
                                  open={open}
                                  anchorEl={anchorRef.current}
                                  role={undefined}
                                  placement="bottom-end"
                                  transition
                                  disablePortal
                                >
                                  {({ TransitionProps, placement }) => (
                                    <Grow
                                      {...TransitionProps}
                                      style={{
                                        transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
                                      }}
                                    >
                                      <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                          <MenuList
                                            autoFocusItem={open}
                                            id="composition-menu"
                                            aria-labelledby="composition-button"
                                          >
                                            <MenuItem>
                                              <Link onClick={handleClose} to={`relock`} className="stake-button-link">
                                                Relock
                                              </Link>
                                            </MenuItem>
                                            <MenuItem>
                                              <Link
                                                onClick={handleClose}
                                                to={`transferownership`}
                                                className="stake-button-link"
                                              >
                                                TransferOwnership
                                              </Link>
                                            </MenuItem>
                                            <MenuItem>
                                              <Link
                                                onClick={handleClose}
                                                to={`increment-lock`}
                                                className="stake-button-link"
                                              >
                                                Increment Lock
                                              </Link>
                                            </MenuItem>
                                            <MenuItem>
                                              <Link
                                                onClick={handleClose}
                                                to={`split-lock`}
                                                className="stake-button-link"
                                              >
                                                Split Lock
                                              </Link>
                                            </MenuItem>
                                            {new Date(lockEndTime * 1000) < new Date() && (
                                              <MenuItem>
                                                <Link
                                                  onClick={handleClose}
                                                  to={`withdraw`}
                                                  className="stake-button-link"
                                                >
                                                  Withdraw
                                                </Link>
                                              </MenuItem>
                                            )}
                                          </MenuList>
                                        </ClickAwayListener>
                                      </Paper>
                                    </Grow>
                                  )}
                                </Popper>
                              </div>
                            </div>
                            <div style={{ display: "flex", marginTop: "10px", justifyContent: "space-between" }}>
                              <Typography variant="h6">{new Date(lockEndTime * 1000).toUTCString()}</Typography>
                              <Typography variant="h6">
                                {`In `}
                                {trim((lockEndTime - lockStartTime) / 3600 / 24, 0)}
                                {` Days`}
                              </Typography>
                            </div>
                          </>
                        )}
                        {tab === "relock" && (
                          <>
                            <Link to={`main`} className="stake-button-link">
                              {`← Back`}
                            </Link>
                            <div style={{ marginTop: "10px" }}>
                              <Typography variant="h5">{`Current lock date`}</Typography>
                              <Typography variant="body2">{new Date(lockEndTime * 1000).toUTCString()}</Typography>
                            </div>
                            <div className={`stake-user-data`} style={{ marginTop: "10px" }}>
                              <Typography variant="h6">Unlock Date</Typography>
                              <DateTimePicker
                                variant="inline"
                                inputVariant="outlined"
                                value={selectedDate}
                                onChange={handleDateChange}
                                style={{ width: "100%" }}
                              />
                              <Typography
                                variant="body2"
                                className="stake-wallet-notification"
                                style={{ marginTop: "4px" }}
                                color="secondary"
                              >
                                Lock date needs to be after current lock date
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                variant="h6"
                                className="stake-wallet-notification"
                                style={{ marginTop: "10px" }}
                              >{`1% fee on relocks`}</Typography>
                            </div>
                            <Button
                              className="stake-button"
                              variant="contained"
                              color="primary"
                              disabled={isPendingTxn(pendingTransactions, "relocking")}
                              onClick={() => {
                                onRelock();
                              }}
                            >
                              {txnButtonText(pendingTransactions, "relocking", "Relock")}
                            </Button>
                          </>
                        )}
                        {tab === "transferownership" && (
                          <>
                            <Link to={`main`} className="stake-button-link">
                              {`← Back`}
                            </Link>
                            <div style={{ marginTop: "10px" }}>
                              <Typography variant="h5">{`Transfer ownership`}</Typography>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                              <Typography variant="h6">{`Transfer this lock to a new owner`}</Typography>
                              <Typography variant="body2">{`The specified address will be able to withdraw the univ2 tokens once the unlock is reached`}</Typography>
                            </div>
                            <Box className="stake-action-row" display="flex" alignItems="center">
                              <FormControl className="unicrypt-input" variant="outlined" color="primary">
                                <OutlinedInput
                                  type="text"
                                  placeholder="New owner address..."
                                  className="stake-input"
                                  onChange={e => setOwnerAddress(e.target.value)}
                                  value={ownerAddress}
                                />
                              </FormControl>
                            </Box>
                            <Button
                              className="stake-button"
                              variant="contained"
                              color="primary"
                              disabled={isPendingTxn(pendingTransactions, "transfering")}
                              onClick={() => {
                                onTransferOwnership();
                              }}
                            >
                              {txnButtonText(pendingTransactions, "transfering", "Transfer")}
                            </Button>
                          </>
                        )}
                        {tab === "increment-lock" && (
                          <>
                            <Link to={`main`} className="stake-button-link">
                              {`← Back`}
                            </Link>
                            <div style={{ marginTop: "10px" }}>
                              <Typography variant="h5">{`Increase Lock`}</Typography>
                            </div>
                            <Box className="stake-action-area">
                              <Typography variant="body2" style={{ textAlign: "end" }}>{`Balance: ${trim(
                                pairBalance,
                                4,
                              )}`}</Typography>
                              <Box className="stake-action-row" display="flex" alignItems="center">
                                <FormControl className="unicrypt-input" variant="outlined" color="primary">
                                  <InputLabel htmlFor="amount-input"></InputLabel>
                                  <OutlinedInput
                                    id="amount-input"
                                    type="number"
                                    placeholder="Enter an amount"
                                    className="stake-input"
                                    value={quantity}
                                    onChange={e => setQuantity(e.target.value)}
                                    labelWidth={0}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Button variant="text" onClick={setMax} color="inherit">
                                          Max
                                        </Button>
                                      </InputAdornment>
                                    }
                                  />
                                </FormControl>
                              </Box>
                              <div>
                                <Typography
                                  variant="h6"
                                  className="stake-wallet-notification"
                                  style={{ marginTop: "10px", marginBottom: "10px" }}
                                >{`1% fee on relocks`}</Typography>
                              </div>
                            </Box>
                            <Button
                              className="stake-button"
                              variant="contained"
                              color="primary"
                              disabled={isPendingTxn(pendingTransactions, "locking")}
                              onClick={() => {
                                onIncrementLock();
                              }}
                            >
                              {txnButtonText(pendingTransactions, "locking", "Lock")}
                            </Button>
                          </>
                        )}
                        {tab === "split-lock" && (
                          <>
                            <Link to={`main`} className="stake-button-link">
                              {`← Back`}
                            </Link>
                            <div style={{ marginTop: "10px" }}>
                              <Typography variant="h5">{`Split Lock`}</Typography>
                              <Typography variant="body2">{`Split this lock into another lock with specified amount. Usefull if you have one large lock thats about to become withdrawable and you'd like to split out say 10% and withdraw for marketing or some other usecase and relock the remaining 90%`}</Typography>
                            </div>
                            <Box className="stake-action-area">
                              <Typography variant="h6">Amount to split to a new lock</Typography>
                              <Typography variant="body2" style={{ textAlign: "end" }}>{`Balance: ${trim(
                                pairBalance,
                                4,
                              )}`}</Typography>
                              <Box className="stake-action-row" display="flex" alignItems="center">
                                <FormControl className="unicrypt-input" variant="outlined" color="primary">
                                  <InputLabel htmlFor="amount-input"></InputLabel>
                                  <OutlinedInput
                                    id="amount-input"
                                    type="number"
                                    placeholder="Enter an amount"
                                    className="stake-input"
                                    value={quantity}
                                    onChange={e => setQuantity(e.target.value)}
                                    labelWidth={0}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Button variant="text" onClick={setMax} color="inherit">
                                          Max
                                        </Button>
                                      </InputAdornment>
                                    }
                                  />
                                </FormControl>
                              </Box>
                              <div>
                                <Typography
                                  variant="h6"
                                  className="stake-wallet-notification"
                                  style={{ marginTop: "10px", marginBottom: "10px" }}
                                >{`Fee: ${chains[chainID] && chains[chainID].ethFee} ${chains[chainID] && chains[chainID].eth}`}</Typography>
                              </div>
                            </Box>
                            <Button
                              className="stake-button"
                              variant="contained"
                              color="primary"
                              disabled={isPendingTxn(pendingTransactions, "locking")}
                              onClick={() => {
                                onSplitLock();
                              }}
                            >
                              {txnButtonText(pendingTransactions, "locking", "Create new lock")}
                            </Button>
                          </>
                        )}
                        {tab === "withdraw" && (
                          <>
                            <Link to={`main`} className="stake-button-link">
                              {`← Back`}
                            </Link>
                            <div style={{ marginTop: "10px" }}>
                              <Typography variant="h5">{`Withdraw`}</Typography>
                            </div>
                            <Box className="stake-action-area">
                              <Typography variant="h6">Amount to withdraw</Typography>
                              <Typography variant="body2" style={{ textAlign: "end" }}>{`Balance: ${trim(
                                lockAmount,
                                4,
                              )}`}</Typography>
                              <Box className="stake-action-row" display="flex" alignItems="center">
                                <FormControl className="unicrypt-input" variant="outlined" color="primary">
                                  <InputLabel htmlFor="amount-input"></InputLabel>
                                  <OutlinedInput
                                    id="amount-input"
                                    type="number"
                                    placeholder="Enter an amount"
                                    className="stake-input"
                                    value={lockedQuantity}
                                    onChange={e => setLockedQuantity(e.target.value)}
                                    labelWidth={0}
                                    endAdornment={
                                      <InputAdornment position="end">
                                        <Button variant="text" onClick={setLockedMax} color="inherit">
                                          Max
                                        </Button>
                                      </InputAdornment>
                                    }
                                  />
                                </FormControl>
                              </Box>
                            </Box>
                            <Button
                              className="stake-button"
                              variant="contained"
                              color="primary"
                              disabled={isPendingTxn(pendingTransactions, "widthdrawing")}
                              onClick={() => {
                                onWithdraw();
                              }}
                            >
                              {txnButtonText(pendingTransactions, "withdrawing", "Withdraw")}
                            </Button>
                          </>
                        )}
                      </div>
                    </>
                  )
                )}
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Zoom>
    </div>
  );
}

export default EditLock;
