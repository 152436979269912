import { Box, SvgIcon } from "@material-ui/core";
import { ReactComponent as PairLogoIcon } from "../assets/tokens/PairLogoIcon.svg";

function PairLogo() {
  let viewBox = "0 0 32 32";
  let style = { height: "32px", width: "32px" };

  return (
    <Box display="flex" alignItems="center" justifyContent="center" width={"64px"}>
      <SvgIcon component={PairLogoIcon} viewBox="0 0 64 32" style={{height: "32px", width: "62px"}} />
    </Box>
  );
}

export default PairLogo;